<template>
  <div>
    <sidebar
      :is-sidebar-active.sync="isSidebarActive"
      :data="sidebarData"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <label>{{ $t('SHOW') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector v-select-custom"
              @input="pageSize"
            />
            <label>{{ $t('ENTRIES') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="b-col-custom"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search') + '...'"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew()"
              >
                <feather-icon icon="PlusIcon" />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
              <!-- <b-form-file
                @change="importFileJson"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".json"
              /> -->
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="respDataReferNews"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Image -->
        <template #cell(image)="data">
          <!-- <span class="align-middle ml-50">{{ data.item.image }}</span> -->
          <b-img
            v-if="data.item.image"
            ref="previewEl"
            rounded
            :src="data.item.image"
            height="80"
          />
          <b-img
            v-else
            ref="previewEl"
            :src="require('@/assets/images/blank/no_image.png')"
            rounded
            height="80"
          />
        </template>

        <!-- Column: User -->
        <template #cell(title)="data">
          <span class="align-middle ml-50">{{ data.item.title }}</span>
        </template>
        <!-- Column: Action -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="sidebarEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('EDIT') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteNews(data.item._id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >{{ currentPage * perPage - (perPage - 1) }} -
              {{
                queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
              }}
              {{ $t('of') }} {{ queriedItems }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="queriedItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="nextPage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Sidebar from './NewsSidebar.vue'
import NewsModule from '../../../store/root/news'

const STORE_MODULE_NAME = 'news'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    Sidebar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: 10,
      isSidebarActive: false,
      perPageOptions: [10, 20, 30, 40, 50],
      sidebarData: {},
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'image', label: this.$t('IMAGE'), sortable: false },
        { key: 'title', label: this.$t('Title'), sortable: false },
        { key: 'content', label: this.$t('Content'), sortable: false },
        { key: 'link', label: this.$t('Link'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respDataReferNews() {
      return store.state.news.respDataReferNews.data
    },
    queriedItems() {
      return store.state.news.respDataReferNews.max || 50
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, NewsModule)
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/getNews`, obj)
      .then(result => {
        console.log('fetch Success : ', result)
        console.log(this.respDataReferNews)
        this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  methods: {
    sidebarAddNew() {
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    sidebarEdit(item) {
      console.log(item)
      this.sidebarData = item
      this.isSidebarActive = true
    },
    deleteNews(id) {
      store
        .dispatch(`${STORE_MODULE_NAME}/deleteNews`, id)
        .then(result => {
          console.log('fetch Success : ', result)
          this.showToast('success', 'top-right', 'Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.showToast('danger', 'top-right', 'Error ', 'XCircleIcon')
        })
    },
    pageSize(size) {
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/getNews`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
          this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
      }
      store
        .dispatch('news/getNews', obj)
        .then(result => {
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    // importFileJson(input) {
    //   let reader = []
    //   if (input.target.files && input.target.files[0]) {
    //     reader = new FileReader()
    //     reader.onload = e => {
    //       store
    //         .dispatch('hospital/importJSON', { file: e.target.result })
    //         .then(() => {})
    //         .catch(err => {
    //           console.error(err)
    //         })
    //     }
    //     reader.readAsText(input.target.files[0])
    //   }
    // },
    showToast(variant, position, title, icon) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
