import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respDataReferNews: [],
  },
  getters: {
  },
  mutations: {
    GET_NEWS(state, payload) {
      state.respDataReferNews = payload
    },
    ADD_NEWS(state, payload) {
      state.respDataReferNews.data.unshift(payload)
    },
    DELETE_NEWS(state, id) {
      const ItemIndex = state.respDataReferNews.data.findIndex(data => data._id === id)
      state.respDataReferNews.data.splice(ItemIndex, 1)
    },
    UPDATE_NEWS(state, payload) {
      const productIndex = state.respDataReferNews.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respDataReferNews.data[productIndex], payload)
    },
  },
  actions: {
    getNews({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`api/web/root/news/getNews?pagesize=${item.pageSize}&currentPage=${item.currentPage}`)
          .then(response => {
            console.log(response)
            commit('GET_NEWS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addNews({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/root/news/addNews', item)
          .then(response => {
            console.log(response.data.data)
            commit('ADD_NEWS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteNews({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/root/news/deleteNews/${item}`)
          .then(response => {
            commit('DELETE_NEWS', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateNews({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/root/news/updateNews/${item._id}`, item)
          .then(response => {
            commit('UPDATE_NEWS', response.data.data)
            // commit('UPDATE_DATA', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
